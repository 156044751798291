import * as PERMISSION_CONFIG  from '../../maps/permission'

export const getPermission = permissionArr => {
  let results = []
  permissionArr.forEach(_permission => {
    let name = _permission.group,
      type = _permission.name,
      result = []
    
    if(PERMISSION_CONFIG[name]) {
      type = type.trim().toLowerCase().replace(' ', '_')

      const configName = PERMISSION_CONFIG[name],
            isIncludedKey = Object.keys(configName).includes(type)

      if(isIncludedKey) {
        result = PERMISSION_CONFIG[name][type]
        results = [...results, ...result]
      }
    }
  })

  return results 
}