import { useHistory } from 'react-router-dom'

import cookies from '../../../utils/cookies_ts'
import { client } from '../../../utils/forstok'

import LinkComponent from '../../../components/link/link_ts'

import { isProduction } from '../../../assets/javascripts/function_ts'

import type { TMouseEvent } from '../../../typeds/base.typed'

import Auth from './auth/auth'

export const destroyUser = async (callback?: () => void) => {
  await client.clearStore()
  if (process.env.NODE_ENV === 'development') {
    cookies.remove('token', { path: '/' })
    cookies.remove('permission', { path: '/' })
    cookies.remove('password', { path: '/' })
  } else {
    cookies.remove('token', { path: '/', domain: '.forstok.com' })
    cookies.remove('permission', { path: '/', domain: '.forstok.com' })
    cookies.remove('password', { path: '/', domain: '.forstok.com' })
  }
  window.sessionStorage.clear()
  window.localStorage.clear()
  callback && callback()
}

export const expiredUser = () => {
  return destroyUser(() => {
    cookies.set('referrerURL', window.location.pathname+window.location.search+window.location.hash, { path: '/' })
    window.location.href = '/dashboard/users/login?k=exp'
  })
}

const Logout = ({ mode }:{ mode?: string }) => {
  const history = useHistory()
  
  const handleClick: TMouseEvent = e => {
    e.preventDefault()
    destroyUser(() => {
      cookies.remove('referrerURL', { path: '/' })
      isProduction() ? Auth.logout(() => window.location.href = 'https://new.forstok.com') : Auth.logout(() => history.push('/'))
      if(process.env.REACT_APP_CHAT_TOKEN) window.fcWidget && window.fcWidget.destroy()
    })
  }

  return (
    <LinkComponent mode={mode || 'clearR'} onClick={handleClick}>Logout</LinkComponent>
  )
}

export default Logout