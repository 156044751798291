import { forstokCookies } from '../../../utils/forstok'

import { getStorage, setStorage } from '../../../assets/javascripts/function'

import Auth from './auth/auth'
import { destroyUser } from './logout'

let controller = new AbortController()

export const scanUser = async () => {
  const users = getStorage('users', null, true)

	let result = false
	
  const forstokToken = forstokCookies.get('token')

	if(forstokToken) {
		controller.abort()
		controller = new AbortController()
		const response = await fetch('https://integration.forstok.com/dashboard_user', {
			method: 'GET',
			headers: {
				'authorization': `Bearer ${forstokToken}`,
				'Content-Type': 'application/json',
			}
		})
	
    const res = await response.json()
		result = res.success
	
    if(!result) destroyUser()
		else {
			if(res.data.dashboard_user.freshchat_restore_id) {
				let newUsers = Object.assign({}, users) 
        newUsers.freshchat_restore_id = res.data.dashboard_user.freshchat_restore_id
        setStorage('users', JSON.stringify(newUsers), 'local')
			}
			Auth.login(() => {
				if(process.env.REACT_APP_WEB_CHAT_HOST) window.fcWidget && window.fcWidget.destroy()
			})
		}
	}
	return result
}