export const dashboard = {
  all_access: [
    { subject: 'dashboard', action: 'load'},
    { subject: 'dashboard_report', action: 'read'}
  ], 
  basic_access: [
    { subject: 'dashboard', action: 'load'}
  ],
  no_access: []
}

export const items = {
  all_access: [
    { subject: 'items', action: 'load'},
    { subject: 'item_add_all', action: 'read'},
    { subject: 'item_bulk', action: 'read'},
    { subject: 'item_bulk_action', action: 'read'},
    { subject: 'item_detail_bulkAction', action: 'read'},
    { subject: 'item_detail_item_action', action: 'read'},
    { subject: 'item_detail_variant_edit', action: 'read'},
    { subject: 'item_detail_variant_adjust', action: 'read'},
    { subject: 'item_detail_variant_detail', action: 'read'},
    { subject: 'item_detail_variant_detail_listing', action: 'read'}
  ], 
  basic_access: [
    { subject: 'items', action: 'load'},
  ],
  no_access: []
}

export const orders = {
  all_access: [
    { subject: 'orders', action: 'load'},
    { subject: 'order_add_all', action: 'read'},
    { subject: 'order_detail_order_action', action: 'read'},
    { subject: 'order_bulk_action', action: 'read'},
    { subject: 'order_check_all', action: 'read'},
    { subject: 'order_check', action: 'read'},
  ], 
  basic_access: [
    { subject: 'orders', action: 'load'},
  ],
  no_access: []
}

export const customers = {
  all_access: [
    { subject: 'customers', action: 'load'},
  ], 
  basic_access: [
    { subject: 'customers', action: 'load'},
  ],
  no_access: []
}

export const reporting = {
  all_access: [
    { subject: 'reporting', action: 'load'},
  ], 
  basic_access: [
    { subject: 'reporting', action: 'load'},
  ],
  no_access: []
}

export const integration = {
  all_access: [
    { subject: 'integration', action: 'load'},
  ], 
  basic_access: [
    { subject: 'integration', action: 'load'},
  ],
  no_access: []
}

export const settings = {
  all_access: [
    { subject: 'settings', action: 'load'},
  ], 
  basic_access: [
    { subject: 'settings', action: 'load'},
  ],
  no_access: []
}

export const promotions = {
  all_access: [
    { subject: 'promotions', action: 'load'},
  ], 
  basic_access: [
    { subject: 'promotions', action: 'load'},
  ],
  no_access: []
}

export const analytics = {
  all_access: [
    { subject: 'analytics', action: 'load'},
  ], 
  basic_access: [
    { subject: 'analytics', action: 'load'},
  ],
  no_access: []
}

export const returns = {
  all_access: [
    { subject: 'returns', action: 'load'},
  ], 
  basic_access: [
    { subject: 'returns', action: 'load'},
  ],
  no_access: []
}

export const chats = {
  all_access: [
    { subject: 'chats', action: 'load'},
  ], 
  basic_access: [
    { subject: 'chats', action: 'load'},
  ],
  no_access: []
}

export const prints = {
  all_access: [
    { subject: 'prints', action: 'load'},
  ], 
  basic_access: [
    { subject: 'prints', action: 'load'},
  ],
  no_access: []
}

export const inventory = {
  all_access: [
    { subject: 'inventory', action: 'load'},
  ], 
  basic_access: [
    { subject: 'inventory', action: 'load'},
  ],
  no_access: []
}