import { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import MyAbility from '../../../../utils/ability'

import cookies from '../../../../utils/cookies'
import { client } from '../../../../utils/forstok'

import { Can } from '../../../../components/can/can.component'

import { getStorage, setStorage } from '../../../../assets/javascripts/function'
import { getPermission } from '../../../../assets/javascripts/config'

import Auth from './auth'
import { scanUser } from '../log'
import { expiredUser } from '../logout_ts'

const ProtectedRoute = (props) => {
  const users = getStorage('users', undefined, true);
  (!users || !users?.profile_id || !users?.id || !users?.credentials) && expiredUser();

  const [ isFirstLoad, setFirstLoad ] = useState(true),
        [ Authenticated, setAuthenticated ] = useState(cookies.get('token') ? true : false),
        [ OrderV1Accessable, setOrderV1Accessable ] = useState(users && ('has_access_orderV1' in users) ? users.has_access_orderV1 : null)

  useEffect(() => {
    if (isFirstLoad) {
      if (Auth.isAuthenticated()) {
        scanUser(cookies.get('token')).then((result) => {
          if (result) setAuthenticated(true)
          else setAuthenticated(false)
        }).catch(error => {
          console.error('Error Checking Login', error)
          if (!cookies.get('token')) setAuthenticated(false)
        })
      }

      const getOrderAccess = async () => {
        const response = await fetch('https://core.forstok.com/api/v1/orderv1', {
            method: 'GET',
            headers: {
              'authorization': cookies.get('token') ? `Bearer ${cookies.get('token')}` : '',
              'Content-Type': 'application/json',
            }
          })
        const result = await response.json()
        let newUsers = Object.assign({}, users) 
        newUsers.has_access_orderV1 = result.has_access
        setStorage('users', JSON.stringify(newUsers), 'local')
        setOrderV1Accessable(result.has_access)
        return result
      }
  
      if (OrderV1Accessable === null) {
        getOrderAccess().catch(() => {
          let newUsers = Object.assign({}, users) 
          newUsers.has_access_orderV1 = false
          setStorage('users', JSON.stringify(newUsers), 'local')
          setOrderV1Accessable(false)
        })
      }

      const updateAvailibity = async () => {
        const permission = cookies.get('permission') || null
        if (permission && permission.length) {
          let results = permission.map(_permission => { 
            return {id: _permission.id, group: _permission.group, name: _permission.name}
          })
          MyAbility.update(getPermission(results))
        }
      }
      updateAvailibity().catch((error) => {
        console.log('Error update Availibity', error)
      })
      setFirstLoad(false)
    }
  }, [OrderV1Accessable, isFirstLoad, users])
  
  const { component: Component, as, sections, ...rest } = props   
  
  const ExcludeCan = [ 'chats', 'activity', 'prints' ]

  console.log('typescript')
  
  return !isFirstLoad && (
    <ApolloProvider client={client}>
      <Route  
        {...rest}
        render={props => {
          if (Authenticated) {
            if (!MyAbility) return <Component sections={sections} {...props} /> 
            else {
              return ExcludeCan.includes(as) ? <Component sections={sections} {...props} /> : ( <Can I='load' a={as} ability={MyAbility} passThrough>
                { can => {
                    return (can || as === 'dashboard') ? <Component sections={sections} can={can} {...props} /> : <Redirect exact to='/dashboard/home'/>
                  } 
                }
              </Can> )
            }
          }else {
            expiredUser()
          }
        }}
      />
    </ApolloProvider>
  )
}

export default ProtectedRoute