import type { AnchorHTMLAttributes, ReactNode, Ref, MouseEvent, HTMLAttributes } from 'react'
import loadable from '@loadable/component'
import * as H from 'history'

import { AnchorLinkContainer, ElLinkContainer } from './link_ts.styles'

const LinkRouteComponent = loadable(() => import('./link_route_ts'))

type TLinkBase = {
  children: ReactNode
  mode?: string
  activated?: boolean
  elipsis?: boolean
  shadow?: boolean
  iconRight?: string
  disabled?: boolean
} & (
  (AnchorHTMLAttributes<HTMLAnchorElement> & {
    href?: string
    to?: undefined
    refContainer?: Ref<HTMLAnchorElement>
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  }) | 
  (AnchorHTMLAttributes<HTMLAnchorElement> & {
    href?: undefined
    to: H.LocationDescriptor<H.LocationState> | ((location: H.Location<H.LocationState>) => H.LocationDescriptor<H.LocationState>)
    refContainer?: Ref<HTMLAnchorElement>
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  }) | 
  (HTMLAttributes<HTMLSpanElement> & {
    href?: undefined
    to?: undefined
    refContainer?: Ref<HTMLSpanElement>
    onClick?: (e: MouseEvent<HTMLSpanElement>) => void
  })
)

const LinkComponent = ({ children, mode, refContainer, activated=false, href, to, elipsis=false, shadow=false, iconRight, disabled, ...props }: TLinkBase) => {
  const { onClick } = props
  return href ? <AnchorLinkContainer className='_refLink' mode={mode} ref={refContainer} activated={activated ? +true : +false} elipsis={elipsis ? +true : +false} shadow={shadow ? +true : +false} disabled={disabled ? +true : +false} href={href} iconRight={iconRight} {...props}>{children}</AnchorLinkContainer> : (to ? <LinkRouteComponent to={to} className='_refLink' mode={mode} innerRef={refContainer} activated={activated && true} elipsis={elipsis && true} shadow={shadow && true} iconRight={iconRight} {...props}>{children}</LinkRouteComponent> : <ElLinkContainer className='_refLink' mode={mode} ref={refContainer} activated={activated ? +true : +false} elipsis={elipsis ? +true : +false} shadow={shadow ? +true : +false} disabled={disabled ? +true : +false} iconRight={iconRight} onClick={onClick} {...props}>{children}</ElLinkContainer>)
}

export default LinkComponent