import Cookies from 'universal-cookie'

const cookies = new Cookies()

class Auth {
  constructor() {
    this.authenticated = (cookies.get('token')) ? true : false
  }
  
  login(cb){
    this.authenticated = true
    cb()
  }

  logout(cb) {
    this.authenticated = false
    cb()
  }

  isAuthenticated() {
    return this.authenticated
  }
}

const MyAuth = new Auth()

export default MyAuth